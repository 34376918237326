<!--  -->
<template>
  <div class=''>
    <el-form ref="FormRef" :model="Form" label-width="100px">
      <!-- :rules="editFormRules" -->
      <el-form-item label="用户名" prop="username" style="font-weight:bold;">
        <el-input v-model="Form.username" disabled style="width: 50%;"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile" style="font-weight:bold">
        <el-input v-model="Form.mobile" disabled style="width: 50%;"></el-input>
        <el-button style="color:dodgerblue" type="text" @click="dialogPhoneEdit = true">&nbsp;&nbsp;修改</el-button>
      </el-form-item>
      <el-form-item label="邮箱" prop="email" style="font-weight:bold">
        <el-input v-model="Form.email" disabled style="width: 50%;"></el-input>
        <el-button style="color:dodgerblue" type="text">&nbsp;&nbsp;修改</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="dialogPSW = true">修改密码</el-button>
      </el-form-item>
    </el-form>

    <el-dialog title="修改/绑定手机号" :visible.sync="dialogPhoneEdit" width="40%" :before-close="handleClose">
      <el-form :model="Form">
        <el-form-item label="输入手机号" prop="phone">
          <el-input v-model="Form.randomMobile" style="width: 70%;" placeholder="需要修改/绑定的手机号"></el-input>
          <el-button style="color:dodgerblue" type="text" @click="getCode(Form.randomMobile)">&nbsp;&nbsp;获取验证码
          </el-button>
        </el-form-item>
        <el-form-item label="输入验证码" prop="phone">
          <el-input v-model="Form.code" style="width: 70%;"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPSW = false">取 消</el-button>
        <el-button type="primary" @click="UpdateMobile()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改密码" :visible.sync="dialogPSW" width="40%" :before-close="handleClose">
      <el-form ref="psform" :model="psform" :rules="rules" label-width="100px" style="font-weight:bold">
        <el-form-item label="新密码" prop="newpsw">
          <el-input v-model="psform.newpsw" placeholder="请输入新密码" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="newpsw2">
          <el-input v-model="psform.newpsw2" placeholder="请再输入一次新密码" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile" style="font-weight:bold">
          <el-input v-model="Form.mobile" disabled style="width: 70%;"></el-input>
          <el-button style="color:dodgerblue" type="text" @click="dialogPhoneEdit = true">&nbsp;&nbsp;修改</el-button>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="psform.code" style="width: 70%;"></el-input>
          <el-button style="color:dodgerblue" type="text" @click="getCode(Form.mobile)">&nbsp;&nbsp;获取验证码
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPSW = false">取 消</el-button>
        <el-button type="primary" @click="UpdatePSW(psform.newpsw, psform.newpsw2)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.psform.newpsw2 !== '') {
          this.$refs.psform.validateField('newpsw2');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.psform.newpsw) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    //这里存放数据
    return {
      tabPosition: 'left',
      dialogPhoneEdit: false,
      dialogPSW: false,
      // 查询到的用户信息对象
      Form: {
        username: "",
        mobile: "",
        email: "",
        code: "",
        randomMobile: "",
      },
      psform: {
        newpsw: '',
        newpsw2: '',
        code: '',
      },
      rules: {
        newpsw: [
          { validator: validatePass, trigger: 'blur' }
        ],
        newpsw2: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getInfo() {
      this.$http({
        url: this.$http.adornUrl("/personal/user/info/" + this.uid),
        method: "get",
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          var information = data.user
          this.Form.username = information.uname
          this.Form.email = information.email
          this.Form.mobile = information.phone
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    getCode(phone_number) {
      this.$http({
        url: this.$http.adornUrl("/sms/sendCode"),
        method: "get",
        params: this.$http.adornParams({ phone: phone_number }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.$message({
            message: '验证码已发至您的手机，请注意查收！',
            type: 'success'
          });
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    UpdateMobile() {
      this.$http({
        url: this.$http.adornUrl("/personal/user/updatePhone"),
        method: "post",
        data: this.$http.adornData({
          uid: this.$store.state.personal_module.uid,
          userPhone: this.Form.randomMobile,
          code: this.Form.code
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据");
          console.log(data);
          this.Form.mobile = this.Form.randomMobile
          this.dialogPhoneEdit = false
          this.$message({
            message: '手机号修改成功！',
            type: 'success'
          });
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    UpdatePSW(psw1, psw2) {
      if (psw1 == psw2) {
        this.$http({
          url: this.$http.adornUrl("/personal/user/updatePassword"),
          method: "post",
          data: this.$http.adornData({
            userPhone: this.Form.mobile,
            code: this.psform.code,
            password: psw1
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.dialogPSW = false
            this.$message({
              message: '密码修改成功！',
              type: 'success'
            });
          } else {
            // this.getCaptcha()
            this.$message.error(data.msg);
          }
        });
        this.$message({
          message: '密码传参成功！',
          type: 'success'
        });
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.uid =  this.$store.state.personal_module.uid
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getInfo()
    // this.getCode()
  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
::v-deep .el-tabs__item {
  font-size: 16px !important;
  font-weight: bold
}
</style>